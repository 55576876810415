import React from "react";
import PropTypes from "prop-types";
import "../css/reset.css";
import {
  siteWidth,
  mainWhite,
  mainNavy,
  mainFont,
} from "../components/common/variables";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${mainWhite};
  color: ${mainNavy};
  font-family: ${mainFont};
  max-width: ${siteWidth};
  margin: 0 auto;

  a {
    font-family: ${mainFont};
  }
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <main>{children}</main>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
