import { keyframes } from "styled-components";

// colours used
export const mainCyan = "#15D3AA";
export const mainViolet = "#7B64F6";
export const mainNavy = "#172637";
export const mainWhite = "#ffffff";
export const dirtyWhite = "#F5F7FA";
export const mainGrey = "#A8ACB2";

// website width container
export const siteWidth = "1920px";
export const innerWidth = "1420px";

export const transHover = `all 0.18s ease-out`;

// Media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // → tablet vertical
  small: `(min-width: 960px)`, // → tablet horizontal
  medium: `(min-width: 1620px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // → laptop with short height
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
};

export const mainFont = `'Inter', sans-serif`;

export const moveBgColor = keyframes`
0% {
  background-position: left;
}
100% {
  background-position: right;
}
`;
